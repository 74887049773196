// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-picker-index-tsx": () => import("../src/templates/product-picker/index.tsx" /* webpackChunkName: "component---src-templates-product-picker-index-tsx" */),
  "component---src-templates-landing-page-index-tsx": () => import("../src/templates/landing-page/index.tsx" /* webpackChunkName: "component---src-templates-landing-page-index-tsx" */),
  "component---src-templates-about-service-index-tsx": () => import("../src/templates/about-service/index.tsx" /* webpackChunkName: "component---src-templates-about-service-index-tsx" */),
  "component---src-templates-privacy-policy-index-tsx": () => import("../src/templates/privacy-policy/index.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-index-tsx" */),
  "component---src-templates-terms-of-use-index-tsx": () => import("../src/templates/terms-of-use/index.tsx" /* webpackChunkName: "component---src-templates-terms-of-use-index-tsx" */),
  "component---src-templates-thank-you-index-tsx": () => import("../src/templates/thank-you/index.tsx" /* webpackChunkName: "component---src-templates-thank-you-index-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-us-checkout-tsx": () => import("../src/pages/en-US/checkout.tsx" /* webpackChunkName: "component---src-pages-en-us-checkout-tsx" */),
  "component---src-pages-en-us-frequently-asked-questions-tsx": () => import("../src/pages/en-US/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-en-us-frequently-asked-questions-tsx" */),
  "component---src-pages-en-us-service-maintenance-tsx": () => import("../src/pages/en-US/service-maintenance.tsx" /* webpackChunkName: "component---src-pages-en-us-service-maintenance-tsx" */),
  "component---src-pages-en-us-shopping-cart-tsx": () => import("../src/pages/en-US/shopping-cart.tsx" /* webpackChunkName: "component---src-pages-en-us-shopping-cart-tsx" */),
  "component---src-pages-fi-fi-ostoskori-tsx": () => import("../src/pages/fi-FI/ostoskori.tsx" /* webpackChunkName: "component---src-pages-fi-fi-ostoskori-tsx" */),
  "component---src-pages-fi-fi-palvelun-yllapito-tsx": () => import("../src/pages/fi-FI/palvelun-yllapito.tsx" /* webpackChunkName: "component---src-pages-fi-fi-palvelun-yllapito-tsx" */),
  "component---src-pages-fi-fi-tilaa-nyt-tsx": () => import("../src/pages/fi-FI/tilaa-nyt.tsx" /* webpackChunkName: "component---src-pages-fi-fi-tilaa-nyt-tsx" */),
  "component---src-pages-fi-fi-usein-kysytyt-kysymykset-tsx": () => import("../src/pages/fi-FI/usein-kysytyt-kysymykset.tsx" /* webpackChunkName: "component---src-pages-fi-fi-usein-kysytyt-kysymykset-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-fi-fi-404-tsx": () => import("../src/pages/fi-FI/404.tsx" /* webpackChunkName: "component---src-pages-fi-fi-404-tsx" */)
}

